<template>
  <div>
    <div class="e-breadcrumb">
      自定义配置
    </div>
    <div class="e-card">
      <div  style="margin-top: 28px;margin-left: 68px">
        <p style="font-weight: bold;">一. 小程序开单方式</p>
        <el-radio v-model="opShop.billType" @change="setBillType(opShop.id,1)" size="small" label="1"  style="margin-left: 18px;margin-top: 18px" border>直接开单</el-radio>
        <span style="font-size: 12px">⒈扫码点击自助开单 </span>
        <span style="font-size: 12px;margin-left: 8px">⒉直接开单成功</span>
        <br>
        <el-radio v-model="opShop.billType" @change="setBillType(opShop.id,2)"  label="2" size="small" style="margin-left: 18px;margin-top: 18px" border>选房开单</el-radio>
        <span style="font-size: 12px">⒈扫码点击自助开单 </span>
        <span style="font-size: 12px;margin-left: 8px">⒉选择房间开单成功</span>
      </div>
      <div  style="margin-top: 68px;margin-left: 68px">
        <p style="font-weight: bold;">二. 设置房间</p>
        <div size="small" label="1"  style="margin-left: 18px;margin-top: 10px;width: 28%">
          <span class="room" v-for="room in roomList">{{room.roomName}}</span>
        </div>
        <el-button style="margin-left: 18px;margin-top: 18px" size="small" type="primary" @click="toSaveRoom(opShop)">设 置</el-button>
      </div>
<!--      <div style="margin-top: 68px;margin-left: 68px">-->
<!--        <p style="font-weight: bold;">二. 系统续费 短信充值</p>-->
<!--        <div size="small" label="1"  style="margin-left: 18px;margin-top: 10px;width: 28%">-->
<!--          <span class="room" v-for="room in roomList">{{room.roomName}}</span>-->
<!--        </div>-->
<!--        <el-button style="margin-left: 18px;margin-top: 18px" size="small" type="primary" @click="toSaveRoom(opShop)">设 置</el-button>-->
<!--      </div>-->
      <br>
      <br>
    </div>
    <!-- 新增房间弹框 -->
    <el-dialog title="设置房间" :visible.sync="addVisible" width="300px" center>
      <div class="del-dialog-cnt">
        <el-input size="small" v-model="roomName" placeholder="请输入房间名称"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="saveRoom">确 定</el-button>
       </span>
    </el-dialog>
    </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      roomName:'',
      opShop:{},
      addVisible:false,
      eItem:{},
      roomList:[],
    };
  },

  created() {
    this.getOpShop();
  },

  methods: {
    async getOpShop() {
      let res = await this.$get("/admin/getOpShop");
      this.opShop=res.opShop
      this.roomList=res.roomList
      console.log(this.roomList)
    },
   async setBillType(shopId,billType){
     let res = await this.$post("/admin/updateBillType",{billType:billType,id:shopId});
     if (res.code==200){
       this.getOpShop();
       this.$message.success(res.message)
     }else {
       this.$message.error(res.message)
     }
    },
    toSaveRoom(item){
      this.roomName=''
     this.eItem=item
     this.addVisible=true
    },
   async saveRoom(){
      let data ={
        shopId:this.eItem.id,
        roomName:this.roomName,
      }
      let res = await this.$post("/admin/saveShopRoom",data);
      if (res.code==200){
        this.getOpShop();
        this.addVisible=false
        this.$message.success(res.message)
      }else {
        this.$message.error(res.message)
      }
    },
  },
};
</script>
<style scoped>
  .e-table-wrapper tbody .el-button {
    padding: 2px 0px;
  }
  .room {
    display: inline-block;
    margin-right: 8px;
    border: #6e6e6e solid 1px;
    padding: 2px;
    margin-top: 8px;
  }
</style>
